.pooly {
    height: 80px;
}
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 2147483647; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}


/* Modal Content */
.modal-content {
  color: rgb(30, 1, 41);
  background-color: #e8cbf8;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}



.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid purple;
  border-right: 8px solid pink;
  border-bottom: 8px solid purple;
  border-left: 8px solid pink;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.smallLoader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid purple;
  border-right: 6px solid pink;
  border-bottom: 6px solid purple;
  border-left: 6px solid pink;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.inlineDiv {
  display: inline-block;
  white-space: nowrap;
  color: #7939ae;
  cursor: pointer;
}
.modalDesc {
  font-weight:400!important;
}
.closeModal {
  cursor: pointer;
  text-align: right;
  font-size:12px;
}
.luckyButton {
  border-color:rgb(77, 30, 94);
  border-radius: 5px;
}

.close {
  position: absolute;
  right: 14px;
  top: 10px;
  width: 15px;
  height: 15px;
  opacity: 0.3;
  display: inline;
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 15px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
