.App {
  height: auto;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.token {
  height: 25px;
  padding-left: 1.5em;
}
.no-left {
  padding-left: 0em;
}
a {
  color: #7939ae;
}
.numb-purp {
  color: #7333db;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.myButton {
  left: 300px;
  /* width:30px!important; */
  font-size: 14px !important;
  border-radius: 10px;
  border: grey;
  background-color: white;
  margin-top: 9px;
  vertical-align: top;
  cursor: pointer;
}

.wallet-div {
  position: absolute;
  right: 80px;
  display: inline;
}

.buy {
  color: green;
}

.sell {
  color: red;
}
.transactions .card-content {
  padding: 0;
}

.transactions table {
  width: 100%;
}
.table-wrapper {
  border-radius: 16px;
}
.transactions .table-wrapper {
  overflow: auto;
  max-height: 90vh;
}

.transactions .card .card-header {
  border-bottom: 1px solid rgba(24, 28, 33, 0.06);
}
.card {
  max-width: 969px !important;
}
.transactions .card {
  border-radius: 6px;
}
.switch-btn {
  background-color: #63c1da;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border-radius: 9999px;
  cursor: pointer;
}
.navcolor {
  color: red;
}
.emoji {
  height: 25px;
  padding-left: 5px;
}
.token-icon {
  height: 25px;
  width: 25px;
  padding-left: 3px;
}
.left-three {
  padding-left: 3px;
  margin-left: 5px;
}
.no-bottom {
  margin-bottom: 0 !important;
}
th,
td {
  font-family: "verdana" !important;
  font-weight: 800 !important;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
  padding-bottom: 40px;
  /* background: linear-gradient(334deg,#4c249f 28%,rgba(255,119,225,.9) 164%),#290b5a; */
  background: linear-gradient(334deg, #24789f 28%, rgb(202 119 255 / 88%) 164%),
    #290b5a0d;
  color: white !important;
}
.column {
  /* background-color:#2e0a59!important;
  color:white; */
  /* background: linear-gradient(334deg,#4c249f 28%,rgba(255,119,225,.9) 164%),#290b5a!important; */
  background: linear-gradient(334deg, #24789f 28%, rgb(202 119 255 / 88%) 164%),
    #290b5a0d;
  color: rgb(131, 115, 115) !important;
  background-color: #341662 !important;
}
.remove-underline {
  text-decoration: none;
}
.coinicon {
  width: 16px !important;
  height: 16px;
}

.icon {
  width: 25px;
}
/* 
.relative-div {
  position:relative;
}
.child {
  position: fixed;
  top: 0;
} */

.child1 {
  left: 0;
  opacity: 0.95;
}

.child2 {
  left: 20px;
}

.navbar-brand {
  padding-left: 50px;
  /* color: white!important; */
  color: #b7f8f3 !important;
  font-size: 30px;
  font-weight: 600;
}
.dropdown-toggle {
  color: white !important;
  font-size: 20px;
  font-weight: 600;
}
.dropdown {
  padding-left: 400px;
}
.dropdown-menu {
  left: 400px;
}

.topData {
  display: inline-block;
}
.addressText {
  display: inline-block;
}
.protocol table {
  display: inline;
  border-radius: 0.25em;
  margin: 5px;
  overflow: hidden;
  margin-left: 100px;
}
.table-header {
  font-size: 22px;
  color: black;
  padding: 30px;
}
.table-text {
  font-size: 16px;
  color: black;
}
.tdcenter td {
  text-align: center;
}
.protocol td {
  background-color: white;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.protocol thead {
  border-radius: 10px;
}
@media only screen and (max-width: 1280px) {
  .wallet-div {
    right: 400px;
  }
}
@media only screen and (max-width: 900px) {
  .wallet-div {
    right: 65px;
  }
}

@media only screen and (max-width: 500px) {
  .wallet-div {
    right: 20px;
  }
  .top-bar-span {
    font-size: 11px;
  }
}

@media only screen and (max-width: 600px) {
  .protocol table {
    margin-left: 10px;
  }

  .dropdown {
    padding-left: 80px;
  }
  .addressText {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .addressTextShort {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    /* text-overflow: ellipsis; */
  }
  .dropdown-menu {
    left: 100px;
  }
  /* .topData {
    display:block;
  } */
}
@media only screen and (max-width: 350px) {
  .addressTextOldPhones {
    width: 80px;
  }
}
.navbar {
  /* background-color: #2d0b5a!important; */
  /* background-color: #2d0b5a87!important; */
  background-color: #3b166c7a !important;
}
.card-header {
  padding: 0px !important;
  font-size: 18px;
  display: inline !important;
  max-height: 70px !important;
}
.card-header-tall-mobile {
  max-height: 100px !important;
}

.address-input {
  background-color: #9846b852;
  border-radius: 5px;
  width: 400px;
  font-size: 16px;
}
.blue-hover:hover {
  background-color: #b8f8f266;
}
.stamp {
  transform: rotate(0deg);
  color: #2775ca;
  font-size: 10px;
  font-weight: 700;
  border: 2px solid #2775ca;
  display: inline-block;
  padding: 2px 4px;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: "Courier";
  /* -webkit-mask-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png');
  -webkit-mask-size: 944px 604px; */
  /*   mix-blend-mode: multiply; */
}

.purple-hover:hover {
  background-color: #e2c8fa;
}
.claimStamp {
  transform: rotate(0deg);
  color: #800080;
  font-size: 11px;
  font-weight: 700;
  border: 2px solid #800080;
  display: inline-block;
  padding: 2px 4px;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: "Courier";
  /* -webkit-mask-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png');
  -webkit-mask-size: 944px 604px; */
  mix-blend-mode: multiply;
}
.tablemax {
  max-width: 700px;
}

.actionButton {
  color: #800080;
  font-size: 15px;
  font-weight: 700;
  border: 2px solid #800080;
  display: inline-block;
  padding: 2px 4px;
  text-transform: uppercase;
  border-radius: 0.5rem;
  font-family: "Courier";
  /* -webkit-mask-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png');
  -webkit-mask-size: 944px 604px; */
  /* mix-blend-mode: multiply; */
}
.actionButton:hover {
  background-color: #e3b8f82e;
}

.expired-stamp {
  color: rgb(147, 131, 131) !important;
  border-color: rgb(107, 103, 103);
}

@media only screen and (max-width: 350px) {
  .address-input {
    width: 320px;
    size: 8px;
  }
}
a.dropdown-item:hover {
  background-color: #e0b3f3 !important;
  color: #0a0a0a;
}
.navbar-toggler-icon {
  background-image: url("https://icon-library.com/images/hamburger-menu-icon-png-white/hamburger-menu-icon-png-white-10.jpg") !important;
}
.twenty-two {
  font-size: 18px;
}
.banner {
  background-color: #4c249f;
  display: inline-block;
  padding: 8px;
  border: 2px solid #4c249f;
  color: #f3f9fb;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.transactions .card .card-header {
  background-color: #eee5fc;
}
.send-button{
  border:1px solid black;
  border-radius:10px;
}
.card-header-title {
  padding-top: 1.2rem !important;
}
.section {
  padding: 2.5rem !important;
}
.header-text {
  font-size: 30px;
}

@media (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
  .header-text {
    font-size: 26px;
  }
  .show-mobile {
    display: inline;
  }

  .header-mobile {
    font-size: 16px;
  }

  .section {
    padding: 1.5rem !important;
  }
}

@media only screen and (min-width: 300px) {
  .cool-pooly {
    width: 300px;
  }
}

@media only screen and (min-width: 767px) {
  .cool-pooly {
    width: 500px;
  }
}

@media only screen and (min-width: 767px) {
  .show-mobile {
    display: none;
  }
}

.small-header {
  font: size 18px;
  padding-top: 35px;
}

.token-right {
  margin-left: -7px;
}

.small-balance {
  font-size: 10px;
}
.yo-soy {
  cursor: pointer;
  padding-left: 10px;
  width: 36px;
}
.yo-soy:hover {
  fill: #1fb0cd;
}
.open-wallet {
  cursor: pointer;
}
.max-balance {
  color: #7333db;
  cursor: pointer;
}
.wallet-message {
  font-size: 10px;
  color: red;
}

.yosoy {
  width: 75px;
}
.amount-input {
  text-align: right;
  width: 236px;
}
.amount-container {
  border: none;
  border-color: #806d99;
  border-radius: 3px;
  padding: 8px;
  margin-left: 15px;
  margin-right: 15px;
}
.right-float {
  float: right;
}
.margint {
  margin-top: 3px;
}
.github {
  margin-right: 25px;
  width: 30px;
}
.discord {
  margin-right: 10px;
  width: 35px;
}
.boticon {
  float: right;
}
